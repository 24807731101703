body {
  margin: 0;
  padding: 0;
  font-family: 'open sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5em;
  color: #3a3a3a;
}

h1,
h2 {
  padding-bottom: 18px;
  margin-bottom: 2px;
  line-height: 1em;
}
h1 {
  margin-top: 60px;
  font-size: 34px;
}
h2 {
  border-bottom: 1px solid #e8e8e8;
  margin-top: 40px;
  font-size: 24px;
}
a {
  text-decoration: none;
}
