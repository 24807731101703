.page {
  margin: 80px auto 100px;
  padding: 0 20%;
}

@media (max-width: 900px) {
  .page {
    padding: 0 10%;
  }
}

@media (max-width: 500px) {
  .page {
    padding: 0 5%;
  }
}

.item {
  padding-top: 10px;
  padding-bottom: 10px;
}

.link {
  color: #000;
}
.link:hover {
  cursor: pointer;
  border-bottom: 3px solid #000;
}

.selectionby {
  color: #888;
}
.selectionbylink {
  color: #888;
}
.contributionsby {
  color: #888;
  font-size: 92%;
}
.builtby {
  color: #888 !important;
  display: inline-block;
  margin-top: 100px;
  font-size: 90%;
}
.selectionbylink:hover,
.builtby:hover {
  text-decoration: underline;
}
